/* eslint-disable prefer-arrow-callback */
'use strict';

$(document).ready(() => {
    require('./giftRegistry/giftRegistry');
    //Bind Google Address Lookup
    // cartridges\plugin_giftregistry\cartridge\client\default\js\giftRegistry.js
    const googleAddress = require('../../../../../app_toysrus/cartridge/client/default/js/components/googleaddress');
    googleAddress.init();

    $('.js-barcode').each(function () {
        const jsBarcode = require('jsbarcode');
        var $this = $(this);
        jsBarcode($this.get(0), '2610' + $this.attr('data-registry-number'), { format: 'EAN13' });
    });
    const BabyRegistryThankYouSelector = $('#b-baby_registry-thank-you-link');
    const BabyRegistryChecklistSelector = $('#b-baby_registry-checklist-link');
    const BabyRegistryChoicesChecklistSelector = $('#b-baby_registry-choices-checklist-link');
    const thankYouHomeURL = BabyRegistryThankYouSelector.attr('href');
    const checklistHomeURL = BabyRegistryChecklistSelector.attr('href');
    const choicesChecklistHomeURL = BabyRegistryChoicesChecklistSelector.attr('href');
    const url = window.location.href;
    const urlObj = new URL(url);
    const registryNumber = urlObj.searchParams.get('registryNumber');
    BabyRegistryThankYouSelector.attr('href', thankYouHomeURL + '?registryNumber=' + registryNumber);
    BabyRegistryChecklistSelector.attr('href', checklistHomeURL + '?registryNumber=' + registryNumber);
    BabyRegistryChoicesChecklistSelector.attr('href', choicesChecklistHomeURL + '?registryNumber=' + registryNumber);

    $('.js-b-card_refinement-header_title-complete').each(function () {
        const $this = $(this);
        const $allCategories = $this.closest('.js-refinement').find('.br-interactive-checklist-product-name');
        const $checkedCategories = $allCategories.filter('.checked').length;

        $this.text($checkedCategories + '/' + $allCategories.length);
    });
});
